<template>
    <page-header-wrapper>
        <a-card>
            <div class="table-page-search-wrapper">
                <a-form layout="inline" class="table-head-ground">
                    <div class="table-layer">
                        <a-form-item label="" class="table-head-layout" style="max-width:350px;min-width:300px">
                            <a-range-picker @change="onChange" :value="createdDate" :showTime="{
                                hideDisabledOptions: true,
                                defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                            }" format="YYYY-MM-DD HH:mm:ss" :disabled-date="disabledDate">
                                <a-icon slot="suffixIcon" type="sync" />
                            </a-range-picker>
                        </a-form-item>
                        <a-form-item label="" class="table-head-layout">
                            <a-input v-model="searchData.orderNo" placeholder="请输入商户退款订单号" />
                        </a-form-item>
                        <a-form-item label="" class="table-head-layout" :wrapper-col="{ span: 16 }">
                            <a-select v-model="searchData.refundStatus" placeholder="退款状态" :allowClear="true">
                                <a-select-option v-for="(item) in StatusList" :key="item.value" :value="item.value"
                                    :label="item.label">{{ item.label }}</a-select-option>
                            </a-select>
                        </a-form-item>
                        <!-- payMode -->
                        <a-form-item label="" class="table-head-layout" :wrapper-col="{ span: 16 }">
                            <a-select v-model="searchData.refundMode" placeholder="退款模式" :allowClear="true">
                                <a-select-option v-for="(item) in refundModeList" :key="item.value" :value="item.value"
                                    :label="item.label">{{ item.label }}</a-select-option>
                            </a-select>
                        </a-form-item>
                        <span class="table-page-search-submitButtons">
                            <a-button type="primary" icon="search" @click="queryFunc"
                                :loading="btnLoading">搜索</a-button>
                            <a-button style="margin-left: 8px" icon="reload" @click="reset">重置</a-button>
                            <a-button style="margin-left: 8px" type="primary" icon="search"
                                @click="exportfunc">导出</a-button>
                        </span>
                    </div>
                </a-form>
                <div style="display: flex;margin-bottom: 10px;align-items: center;justify-content: space-between;">
                    <a-button type="primary" @click="showStatistics = !showStatistics">
                        <a-icon type="bar-chart" /> {{ showStatistics ? '关闭统计' : '显示统计' }}
                    </a-button>
                </div>
                <!--统计信息refundCount-->
                <div style="margin-bottom:20px">
                    <div class="statistics-view" v-if="showStatistics" :searchData="searchData">
                        <!-- amount -->
                        <div style="flex: 1;justify-content: center;align-items: center;">
                            <div class="statistics-item-view">
                                <span>退款金额</span>
                                <span class="amount-view">{{ orderInfo.amount | curreny }}<span
                                        class="text-view">元</span></span>
                            </div>
                        </div>
                        <div style="flex: 1;justify-content: center;align-items: center;">
                            <div class="statistics-item-view">
                                <span>退款笔数</span>
                                <span class="amount-black-view">{{ orderInfo.refundCount }}<span
                                        class="text-view">笔</span></span>
                            </div>
                        </div>
                        <div style="flex: 1;justify-content: center;align-items: center;">
                            <div class="statistics-item-view">
                                <span>手续费金额</span>
                                <span class="amount-view">{{ orderInfo.feeAmount | curreny }}<span
                                        class="text-view">元</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 列表渲染 -->
            <JeepayTable @btnLoadClose="btnLoading = false" ref="infoTable" :initData="true" :closable="true"
                :searchData="searchData" :reqTableDataFunc="reqTableDataFunc" :tableColumns="tableColumns"
                rowKey="refundOrderId" :scrollX="1300">
                <template slot="refundStatusSlot" slot-scope="{record}">
                    {{ dict.statsList[record.refundStatus] }}
                </template>
            </JeepayTable>
        </a-card>
    </page-header-wrapper>
</template>
<script>
import JeepayTextUp from '@/components/JeepayTextUp/JeepayTextUp' // 文字上移组件
import JeepayTable from '@/components/JeepayTable/JeepayTable'
import JeepayTableColumns from '@/components/JeepayTable/JeepayTableColumns'
import { req, API_URL_RECONCILIATION_REFUNDLIST, API_URL_RECONCILIATION_REFUNDCOUNT } from '@/api/manage'
import moment from 'moment'
import dict from './dict'
import update from './update'
import request from '@/http/request'
const tableColumns = [
    { key: 'tradeTime', title: '交易时间', dataIndex: 'tradeTime', width: '180px' },
    { key: 'appId', title: 'appId', dataIndex: "appId", width: '260px', ellipsis: true },
    { key: 'refundId', title: '退款ID', dataIndex: 'refundId', width: '260px', ellipsis: true },
    { key: 'payId', title: '支付ID', dataIndex: 'payId', width: '260px', ellipsis: true },
    { key: 'orderNo', title: '商户退款订单号', dataIndex: 'orderNo', width: '260px' },
    { key: 'payOrderNo', title: '商户支付订单号', dataIndex: 'payOrderNo', width: '260px' },
    { key: 'tradeType', title: '交易类型', dataIndex: "tradeType", width: '180px' },
    { key: 'amount', dataIndex: 'amount', title: '退款金额', width: '180px', customRender: (amount) => { return amount ? parseFloat(amount).toFixed(2) : "0.00" } },
    { key: 'feeAmount', dataIndex: 'feeAmount', title: '手续费金额', width: '180px', customRender: (amount) => { return amount ? parseFloat(amount).toFixed(2) : "0.00" } },
    { key: 'currencyType', dataIndex: 'currencyType', title: '货币种类', width: '180px' },
    { key: 'refundStatus', scopedSlots: { customRender: 'refundStatusSlot' }, title: '退款状态', width: '180px' },
    { key: 'refundTime', dataIndex: 'refundTime', title: '退款完成时间', width: '180px' },
    { key: 'refundMode', dataIndex: 'refundMode', title: '退款模式', width: '180px' },]
export default {
    name: 'ReconciliationAdapayRefund',
    mixins: [update],
    components: {
        JeepayTextUp,
        JeepayTable,
        JeepayTableColumns
    },
    filters: {
        curreny: function (data) {
            data = data ? parseFloat(data).toFixed(2) : "0.00";
            return data;
        }
    },
    data() {
        return {
            btnLoading: false,
            tableColumns: tableColumns,
            searchData: {
                createdStart: '', // 选择开始时间
                createdEnd: '', // 选择结束时间
                orderNo: "",
                refundStatus: undefined,
                refundMode: undefined
            },
            dict: dict,
            createdDate: [],
            refundModeList: [
                {
                    value: "支付确认撤销",
                    label: "支付确认撤销"
                },
                {
                    value: "延时分账撤销",
                    label: "延时分账撤销"
                },
                {
                    value: "退款",
                    label: "退款"
                }
            ],
            orderInfo: {},
            showStatistics: false,
        }
    },
    mounted() {
        this.reqOrderCount()
    },
    methods: {
        // 请求统计接口数据
        reqOrderCount() {
            req.list(API_URL_RECONCILIATION_REFUNDCOUNT, this.searchData).then(res => {
                this.orderInfo = res;
            })
        },
        queryFunc() {
            this.btnLoading = true
            this.reqOrderCount()
            this.$refs.infoTable.refTable(true)
        },
        // 请求table接口数据
        reqTableDataFunc: (params) => {
            return req.list(API_URL_RECONCILIATION_REFUNDLIST, params)
        },
        searchFunc() { // 点击【查询】按钮点击事件
            this.$refs.infoTable.refTable(true)
        },
        moment,
        onChange(date, dateString) {
            this.createdDate = dateString;
            this.searchData.createdStart = dateString[0] // 开始时间
            this.searchData.createdEnd = dateString[1] // 结束时间
        },
        disabledDate(current) { // 今日之后日期不可选
            return current && current > moment().endOf('day')
        },
        reset() {
            this.searchData = {
                createdStart: '', // 选择开始时间
                createdEnd: '', // 选择结束时间
                orderNo: "",
                refundStatus: undefined,
                refundMode: undefined
            }
            this.createdDate = [];
            setTimeout(() => { this.$refs.infoTable.refTable(true) }, 1000)
        },
        exportfunc() {
            request.request({
                url: '/mch/reconciliation/adapay/data/asynExportRefundList',
                method: 'post',
                data: this.searchData,
            }).then(res => {
                this.$message.success(res);
            })
        }
    }
}
</script>
<style scoped lang="less">
.statistics-view {
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    flex-direction: row;
    display: flex;
    margin-top: 20px;
    padding: 30px 0px 30px 0px;

}

.statistics-item-view {
    flex-direction: column;
    display: flex;
    text-align: start;
    align-items: center;
}

.amount-view {
    font-size: 20px;
    color: #5485f8;
    font-weight: bold;
    margin-top: 10px;
}

.amount-black-view {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    margin-top: 10px;
}

.amount-h-view {
    font-size: 20px;
    color: #dbaf0e;
    font-weight: bold;
    margin-top: 10px;
}

.text-view {
    font-size: 14px;
    font-weight: normal;
}

.mx-text {
    font-size: 14px;
    font-weight: normal;
    color: #55a2ff;
    padding-left: 10px;
}

::v-deep .ant-upload-list {
    width: 300px !important;
}
</style>